<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="800" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-account</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("weekeditor-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>
            <v-row class="mt-2" v-if="fetchingData"
              ><v-col>
                <v-progress-circular
                  :indeterminate="true"
                  :rotate="0"
                  :size="32"
                  :width="4"
                  color="primary"
                ></v-progress-circular> </v-col
            ></v-row>
            <v-form
              class="mt-5"
              v-if="!fetchingData"
              ref="weekeditor-editor-form"
            >
              <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
                <v-row>
                  <v-col>
                    <ValidationProvider
                      :immediate="editingWeek"
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="week.name"
                        :label="$t('weekeditor-hint-name')"
                        prepend-icon="mdi-account"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <div class="body-1 mb-2">
                  {{ $t("weekeditor-days-of-week") }}
                </div>
                <v-divider />

                <div>
                  <v-row
                    dense
                    :key="index"
                    v-for="(item, index) in week.weekDays"
                  >
                    <v-col>{{ item.day_date | toDayName | capitalize }}</v-col
                    ><v-col>{{ item.day_date | toLocaleDate }}</v-col
                    ><v-col>
                      <v-select
                        dense
                        hide-details
                        outlined
                        :items="holidayItems"
                        v-model="item.holidayState"
                      >
                      </v-select></v-col
                    ><v-col>
                      <v-text-field
                        v-if="item.holidayState != null"
                        dense
                        hide-details
                        outlined
                        v-model="item.holidayName"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <v-divider />

                <div style="height: 10px" />

                <v-row>
                  <v-col>
                    <v-btn
                      width="120"
                      :disabled="invalid || !validated"
                      v-on:click="confirm()"
                      color="orange"
                      >{{ $t("gbl-ok") }}</v-btn
                    >
                    <v-btn width="120" v-on:click="cancel()" color="primary">{{
                      $t("gbl-cancel")
                    }}</v-btn>
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-form>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
//import plannerManager from "../apis/applications";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import plannerManager from "@/apis/planner";

export default {
  data() {
    return {
      week: {
        weekDays: [],
      },
      holidayItems: [
        { text: "Festivo", value: 1 },
        { text: "Pre-festivo", value: 2 },
        { text: "Ordinario", value: null },
      ],

      editingWeek: false,
      fetchingData: false,
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: ["value"],

  mounted() {},

  watch: {},

  methods: {
    dayHolidayChanged(item) {
      console.log(item);
    },

    confirm: function () {
      this.handleSubmit();
    },

    closeDialog: function () {
      this.cancel();
    },

    cancel: function () {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    checkFormValidity(callback) {
      callback(true);
    },

    handleSubmit() {
      this.checkFormValidity((res) => {
        if (!res) return false;
        this.$refs.obs.reset();
        this.$emit("weekConfigured", {
          editingWeek: this.editingWeek,
          week: this.week,
        });
      });
    },

    resetModal() {},

    setWeek(week, editing) {
      this.week = { ...week };
      this.editingWeek = editing;
      if (!this.editingWeek) {
        this.resetModal();
        this.fetchingData = false;
        requestAnimationFrame(() => {
          if (this.$refs.obs) this.$refs.obs.reset();
        });
      } else {
        this.fetchingData = true;
        plannerManager
          .fetchWeekDays(week.id)
          .then((result) => {
            this.$set(this, "week", { ...week, weekDays: result });

            /*this.week = new Vue({
              data: {
                ...week,
                weekDays: result,
              },
            });*/
            for (let x of this.week.weekDays) {
              this.$set(
                x,
                "holidayState",
                x.holiday ? 1 : x.pre_holiday ? 2 : null
              );
            }
            this.fetchingData = false;
            requestAnimationFrame(() => {
              if (this.$refs.obs) {
                this.$refs.obs.validate();
              }
            });
          })
          .catch((err) => {
            console.log(err);
            this.fetchingData = false;
            this.cancel();
          });
      }

      /* eslint-enable no-unused-vars */
    },
  },
};
</script>
