<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <messageBox ref="msgBox" />
      <groupEditor
        @groupConfigured="handleGroup"
        ref="groupEditor"
        v-model="groupEditorDialog"
      ></groupEditor>

      <v-flex md12>
        <v-btn
          :disabled="!$store.state.hasRight('6')"
          v-on:click="addGroup()"
          color="primary"
          >{{ $t("groupmng-add") }}</v-btn
        >
        <base-material-card
          color="primary"
          icon="mdi-account"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("groupmng") }}
            </div>
          </template>
          <v-text-field
            :value="filter"
            @change="(v) => (filter = v)"
            append-icon="mdi-magnify"
            class="mr-auto pl-1"
            :label="$t('gbl-search')"
            hide-details
            single-line
            style="max-width: 250px"
          />

          <div class="vertical-spacer" />
          <v-data-table
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="groups"
            :options.sync="options"
            :server-items-length="totalGroups"
            :loading="loading"
            :footer-props="dataTableFooterOptions"
          >
            <template v-slot:item.rights_amount="{ item }">
              {{ item.rights ? item.rights.split(",").length : 0 }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    :disabled="!$store.state.hasRight('7')"
                    x-small
                    text
                    icon
                    color="primary"
                    @click.stop="editItem(item)"
                    v-on="on"
                    ><v-icon>mdi-account-edit</v-icon></v-btn
                  ></template
                ><span>{{ $t("groupmng-modify-group") }}</span></v-tooltip
              >

              <v-tooltip bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    :disabled="!$store.state.hasRight('8')"
                    x-small
                    text
                    icon
                    color="error"
                    @click.stop="deleteItem(item)"
                    v-on="on"
                    ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                  ></template
                ><span>{{ $t("usrmng-remove-user") }}</span></v-tooltip
              >
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import userManager from "../apis/users";
import staticData from "../apis/static";

import messageBox from "../components/MessageBox.vue";
import groupEditor from "../components/GroupEditor.vue";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      groups: [],
      totalGroups: 0,
      loading: false,
      options: {
        sortBy: ["name"],
        sortDesc: [false],
        itemsPerPage: 50,
        page: 1,
      },
      headers: [],
      perPage: staticData.defaultPerPage,
      filter: "",

      groupEditorDialog: false,
    };
  },

  components: {
    groupEditor,
    messageBox,
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("groupmng-tbl-name"),
        align: "left",
        sortable: true,
        value: "name",
      },
      {
        text: this.$t("groupmng-tbl-rights"),
        align: "center",
        sortable: true,
        value: "rights_amount",
      },
      { text: "", align: "center", sortable: false, value: "actions" },
    ];
  },

  watch: {
    options: {
      handler() {
        this.reloadGroups(true);
      },
      deep: true,
    },

    filter: {
      handler() {
        this.currentPage = 1;
        this.reloadGroups(false);
      },
    },
  },

  methods: {
    reloadGroups: function (showLoader) {
      this.fetchGroups(showLoader)
        .then((data) => {
          this.groups = data.items;
          this.totalGroups = data.totalCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchGroups(showLoader) {
      if (showLoader) this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        userManager
          .fetchGroups(ctx)
          .then((result) => {
            if (showLoader) this.loading = false;
            resolve(result);
          })
          .catch((err) => {
            if (showLoader) this.loading = false;
            console.log(err);
            reject();
          });
      });
    },

    handleGroup(event) {
      this.groupEditorDialog = false; // Close dialog

      if (event.editingGroup) {
        let group = event.group;
        userManager
          .updateGroup(group)
          .then(() => {
            this.reloadGroups(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        let group = event.group;
        userManager
          .addGroup(group)
          .then(() => {
            this.reloadGroups(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    deleteItem(item) {
      let groupId = item.id;
      this.$refs.msgBox
        .show(
          this.$t("groupmng-msgbox-confirm-delete"),
          this.$t("msgbox-confirm-op")
        )
        .then(() => {
          userManager
            .removeGroup(groupId)
            .then(() => {
              this.reloadGroups(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    editItem(item) {
      let group = JSON.parse(JSON.stringify(item));
      this.$refs.groupEditor.resetModal();
      this.$refs.groupEditor.setModalGroup(group, true, item);
      this.groupEditorDialog = true;
    },

    addGroup() {
      this.$refs.groupEditor.resetModal();
      this.$refs.groupEditor.setModalGroup(
        { name: "Nuovo gruppo", rights: "" },
        false,
        null
      );
      this.groupEditorDialog = true;
    },
  },
};
</script>
