<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="800" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-clock</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("weekadd-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <v-form class="mt-5" ref="weekeditor-editor-form">
              <div class="body-1 mb-2">
                {{ $t("weekeditor-pick-week-start") }}
              </div>

              <v-row justify="center">
                <v-date-picker
                  :locale="$store.state.user.locale"
                  v-model="weekDate"
                  :allowed-dates="allowedDates"
                  width="750"
                  class="mt-4"
                  :disabled="fetchingData"
                  :events="getDateEventColor"
                ></v-date-picker>
              </v-row>

              <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
                <v-row>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="week.name"
                        :label="$t('weekeditor-hint-name')"
                        prepend-icon="mdi-clock"
                        :error-messages="errors"
                        :success="valid"
                        :disabled="fetchingData"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      outlined
                      dense
                      v-model="weekTemplate"
                      :label="$t('weekeditor-hint-template')"
                      prepend-icon="mdi-clock"
                      :disabled="fetchingData"
                      item-text="name"
                      item-value="id"
                      :items="weekTemplates"
                    ></v-select>
                  </v-col>
                </v-row>
                <div
                  v-if="weekAlreadyOpen"
                  class="red--text text--lighten-1 body-1 mb-2"
                >
                  {{ $t("weekeditor-week-already-open") }}
                </div>

                <div v-if="!weekAlreadyOpen" class="body-1 mb-2">
                  {{ $t("weekeditor-days-of-week") }}
                </div>
                <v-divider v-if="!weekAlreadyOpen" />

                <div v-if="!weekAlreadyOpen && week.week_days.length > 0">
                  <v-row
                    dense
                    :key="index"
                    v-for="(item, index) in week.week_days"
                  >
                    <v-col>{{ item.day | toDayName | capitalize }}</v-col
                    ><v-col>{{ item.day | toLocaleDate }}</v-col
                    ><v-col>{{
                      item.holiday
                        ? "Festivo" + " " + item.holidayName
                        : item.pre_holiday
                        ? "Pre-festivo"
                        : ""
                    }}</v-col>
                  </v-row>
                </div>
                <v-divider />

                <div style="height: 10px" />

                <v-row>
                  <v-col>
                    <v-btn
                      width="120"
                      :disabled="
                        invalid ||
                        !validated ||
                        fetchingData ||
                        weekAlreadyOpen ||
                        loadingTemplates
                      "
                      v-on:click="confirm()"
                      color="orange"
                      >{{ $t("gbl-ok") }}</v-btn
                    >
                    <v-btn width="120" v-on:click="cancel()" color="primary">{{
                      $t("gbl-cancel")
                    }}</v-btn>
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-form>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import plannerManager from "@/apis/planner";
import Holidays from "date-holidays";
import templatesManager from "@/apis/templates";

const holidays = new Holidays("IT");

export default {
  data() {
    return {
      week: {
        week_days: [],
        name: null,
      },
      loadingTemplates: false,
      weekDate: null,
      weekTemplate: null,
      weekTemplates: [],
      fetchingData: false,
      weekAlreadyOpen: false,
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: ["value"],

  mounted() {
    this.fetchTemplates();
  },

  watch: {
    weekDate: {
      handler: function (val) {
        if (val) {
          this.week.week_days = [];
          let date = new Date(val);
          let day = date.getDay();
          let dayString = date.getDate().toString();
          let dayPlusSixString = (date.getDate() + 6).toString();

          // Add 6 days to dayString
          if (dayString.length < 2) dayString = "0" + dayString;
          if (dayPlusSixString.length < 2)
            dayPlusSixString = "0" + dayPlusSixString;

          let dayMonthString = (date.getMonth() + 1).toString();
          if (dayMonthString.length < 2) dayMonthString = "0" + dayMonthString;

          this.week.name =
            dayString +
            "-" +
            dayPlusSixString +
            " / " +
            dayMonthString +
            " / " +
            date.getFullYear();
          for (let i = 0; i < 7; i++) {
            let weekDay = new Date(date);
            weekDay.setDate(date.getDate() + i - day + 1);
            let holiday = this.isHolidayDay(weekDay);
            let pre_holiday = this.isPreHolidayDay(weekDay);

            this.week.week_days.push({
              day: weekDay.toISOString().substr(0, 10),
              pre_holiday: pre_holiday ? true : false,
              holiday: holiday ? true : false,
              holidayName: holiday && holiday[0] ? holiday[0].name : "",
            });
          }
          // Check if week is already opened
          this.fetchingData = true;
          this.fetchWeekExistance(date).then((res) => {
            this.fetchingData = false;
            this.weekAlreadyOpen = res > 0;
            this.$refs.obs.validate();
          });
        }
      },
    },
  },

  methods: {
    fetchTemplates() {
      this.loadingTemplates = true;
      return new Promise((resolve, reject) => {
        templatesManager
          .fetchTemplates({})
          .then((res) => {
            this.weekTemplates = [
              {
                id: null,
                name: this.$t("gbl-none-male"),
              },
              ...res.templates,
            ];
            this.loadingTemplates = false;
            resolve(this.templates);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    fetchWeekExistance(day) {
      return new Promise((resolve, reject) => {
        plannerManager
          .fetchWeekStatus(day)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getDateEventColor(date) {
      let events = [];
      if (this.isHolidayDay(date)) events.push("red");
      if (this.isPreHolidayDay(date)) events.push("orange");

      return events;
    },

    isHolidayDay: function (val) {
      let weekDay = new Date(val);
      weekDay.setDate(weekDay.getDate());
      let isHoliday = holidays.isHoliday(weekDay);
      let day = weekDay.getDay();
      if (isHoliday) return isHoliday;
      if (day == 0) return true;
    },

    isPreHolidayDay: function (val) {
      let weekDay = new Date(val);
      weekDay.setDate(weekDay.getDate());
      let tomorrowWeekDay = new Date(val);
      tomorrowWeekDay.setDate(tomorrowWeekDay.getDate() + 1);
      let isPreHoliday = holidays.isHoliday(tomorrowWeekDay);
      let day = weekDay.getDay();
      if (isPreHoliday || day == 6) return true;
    },

    allowedDates: function (val) {
      let date = new Date(val);
      let day = date.getDay();
      return day == 1;
    },

    confirm: function () {
      this.handleSubmit();
    },

    closeDialog: function () {
      this.cancel();
    },

    cancel: function () {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
      this.week = {
        week_days: [],
        name: null,
      };
    },

    checkFormValidity(callback) {
      callback(true);
    },

    handleSubmit() {
      this.checkFormValidity((res) => {
        if (!res) return false;
        this.$refs.obs.reset();
        this.$emit("weekConfigured", {
          week: { ...this.week },
          template: this.weekTemplate,
        });
        this.week = {
          week_days: [],
          name: null,
        };
      });
    },

    resetModal() {
      this.week = {
        week_days: [],
        name: null,
      };
      this.weekDate = null;
      this.weekAlreadyOpen = false;
      this.weekTemplate = null;
    },
  },
};
</script>
<style>
.v-date-picker-header
  .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  color: black !important;
  background-color: white !important;
}
</style>
