<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="800" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-clock</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("shiftadd-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <v-form class="mt-5" ref="weekeditor-editor-form">
              <div class="body-1 mb-2">
                {{ $t("shifteditor-pick-day") }}
              </div>

              <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
                <v-row v-if="!shift.id" justify="center">
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-autocomplete
                        :disabled="fetchingData"
                        clearable
                        dense
                        no-filter
                        v-model="flt_user"
                        :items="flt_users"
                        :loading="isLoadingUser"
                        :search-input.sync="searchUserInput"
                        outlined
                        item-text="display_name"
                        item-value="id"
                        :label="$t('filter-user')"
                        return-object
                      ></v-autocomplete
                    ></ValidationProvider>
                  </v-col>
                </v-row>
                <v-row v-if="shift.id" justify="center">
                  <v-col
                    >{{
                      shift.business_name
                        ? shift.business_name
                        : shift.first_name + " " + shift.last_name
                    }}
                  </v-col>
                </v-row>

                <v-row dense align="center">
                  <v-col cols="6">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        prepend-icon="mdi-calendar"
                        type="date"
                        :label="$t('shifteditor-day')"
                        outlined
                        dense
                        v-model="shift.shift_day"
                        :error-messages="errors"
                        :success="valid"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      class="mt-0 ml-7"
                      v-model="shift.all_day"
                      label="Tutto il giorno"
                    />
                  </v-col>
                </v-row>
                <v-row dense align="center">
                  <v-col cols="6">
                    <ValidationProvider
                      v-if="!shift.all_day"
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        type="time"
                        outlined
                        dense
                        v-model="shift.shift_start_time"
                        :label="$t('shifteditor-time-start')"
                        prepend-icon="mdi-clock"
                        :error-messages="errors"
                        :success="valid"
                        :disabled="fetchingData || shift.all_day == 1"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                    <v-text-field
                      v-if="shift.all_day"
                      type="time"
                      outlined
                      dense
                      v-model="shift.shift_start_time"
                      :label="$t('shifteditor-time-start')"
                      prepend-icon="mdi-clock"
                      disabled
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <ValidationProvider
                      v-if="!shift.all_day"
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        type="time"
                        outlined
                        dense
                        v-model="shift.shift_end_time"
                        :label="$t('shifteditor-time-end')"
                        prepend-icon="mdi-clock"
                        :error-messages="errors"
                        :success="valid"
                        :disabled="fetchingData || shift.all_day == 1"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                    <v-text-field
                      v-if="shift.all_day"
                      type="time"
                      outlined
                      dense
                      v-model="shift.shift_end_time"
                      :label="$t('shifteditor-time-end')"
                      prepend-icon="mdi-clock"
                      disabled
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-select
                      :disabled="fetchingData"
                      clearable
                      dense
                      no-filter
                      v-model="shift.reason"
                      :items="reasons"
                      outlined
                      item-text="name"
                      item-value="id"
                      :label="$t('filter-reason')"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-alert
                      dense
                      v-if="shift.shift_start_time > shift.shift_end_time"
                      type="error"
                      >{{ $t("shifteditor-time-error") }}</v-alert
                    >
                  </v-col>
                </v-row>

                <v-divider />

                <div style="height: 10px" />

                <v-row dense>
                  <v-col>
                    <v-btn
                      width="120"
                      :disabled="
                        invalid ||
                        !validated ||
                        fetchingData ||
                        shift.shift_start_time > shift.shift_end_time
                      "
                      v-on:click="confirm()"
                      color="orange"
                      >{{ $t("gbl-ok") }}</v-btn
                    >
                    <v-btn width="120" v-on:click="cancel()" color="primary">{{
                      $t("gbl-cancel")
                    }}</v-btn>
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-form>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import usersManager from "../apis/users";
import shiftManager from "../apis/shifts";
import config from "../config";

export default {
  data() {
    return {
      fetchingData: false,

      shift: {
        id: null,
        shift_day: null,
        shift_end_time: null,
        shift_start_time: null,
        all_day: 0,
        reason: null,
        user_id: null,
      },

      flt_user: null,
      flt_users: [],
      searchUserInput: null,
      isLoadingUser: false,
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: ["value"],

  mounted() {
    this.fetchReasons();
  },

  watch: {
    searchUserInput(val) {
      clearTimeout(this._searchUserInput);
      if (val) {
        this._searchUserInput = setTimeout(() => {
          this.isLoadingUser = true;
          this.fetchFilterUsers(val)
            .then((res) => {
              this.flt_users = res.items.map((x) => {
                x.display_name = x.first_name + " " + x.last_name;
                return x;
              });
              this.isLoadingUser = false;
            })
            .catch(() => {
              this.isLoadingUser = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        this.shift.user_id = null;
      }
    },

    flt_users: {
      handler() {
        this.shift.user_id = this.flt_user ? this.flt_user.id : null;
      },
    },
  },

  methods: {
    fetchReasons() {
      return new Promise((resolve, reject) => {
        shiftManager
          .fetchReasons()
          .then((results) => {
            this.reasons = [
              { id: null, name: this.$t("gbl-none-female") },
              ...results,
            ];
            resolve(results);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchFilterUsers(filter) {
      return new Promise((resolve, reject) => {
        usersManager
          .fetchFilterUsers(filter)
          .then((results) => {
            resolve(results);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    setShift(shift) {
      this.shift = { ...shift };
    },
    confirm: function () {
      this.$emit("shiftConfigured", this.shift);
    },

    closeDialog: function () {
      this.cancel();
    },

    cancel: function () {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
      this.resetModal();
    },

    resetModal() {
      this.shift = {
        id: null,
        reason: null,
        all_day: 0,
        shift_day: null,
        shift_end_time: null,
        shift_start_time: null,
        user_id: null,
      };
    },
  },
};
</script>
