<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <messageBox ref="msgBox" />
      <weekEditor
        @weekConfigured="handleEditWeek"
        ref="weekEditor"
        v-model="weekEditorDialog"
      ></weekEditor>

      <addWeek
        @weekConfigured="handleAddWeek"
        ref="addWeekEditor"
        v-model="addWeekEditorDialog"
      ></addWeek>

      <v-flex md12>
        <v-btn
          v-if="$store.state.hasRight('34')"
          v-on:click="addWeek()"
          color="primary"
          >{{ $t("weeksshift-manager-add") }}</v-btn
        >
        <base-material-card
          color="primary"
          icon="mdi-account"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("weeksshift-manager") }}
            </div>
          </template>
          <v-text-field
            :value="filter"
            @change="(v) => (filter = v)"
            append-icon="mdi-magnify"
            class="mr-auto pl-1"
            :label="$t('gbl-search')"
            hide-details
            single-line
            style="max-width: 250px"
          />

          <div class="vertical-spacer" />
          <v-data-table
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="weeks"
            :options.sync="options"
            :server-items-length="totalWeeks"
            :loading="loading"
            :footer-props="dataTableFooterOptions"
          >
            <template v-slot:item.actions="{ item }">
              <v-tooltip v-if="$store.state.hasRight('35')" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="secondary"
                    @click.stop="editItem(item)"
                    v-on="on"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  ></template
                ><span>{{
                  $t("weeksshift-manager-modify-week")
                }}</span></v-tooltip
              >
              <v-tooltip v-if="$store.state.hasRight('36')" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="error"
                    @click.stop="deleteItem(item)"
                    v-on="on"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  ></template
                ><span>{{
                  $t("weeksshift-manager-delete-week")
                }}</span></v-tooltip
              >
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import plannerManager from "@/apis/planner";
import staticData from "@/apis/static";
import messageBox from "@/components/MessageBox.vue";
import weekEditor from "@/components/WeekEditor.vue";
import addWeek from "@/components/AddWeek.vue";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      weeks: [],
      loading: false,
      totalWeeks: 0,
      options: {
        sortBy: ["name"],
        sortDesc: [false],
        itemsPerPage: 50,
        page: 1,
      },
      headers: [],
      perPage: staticData.defaultPerPage,
      filter: "",

      weekEditorDialog: false,
      addWeekEditorDialog: false,
    };
  },

  components: {
    weekEditor,
    messageBox,
    addWeek,
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("weeksshift-manager-tbl-name"),
        align: "left",
        sortable: true,
        value: "name",
      },
      {
        text: this.$t("weeksshift-manager-tbl-start-day"),
        align: "center",
        sortable: true,
        value: "start_day",
      },
      {
        text: this.$t("weeksshift-manager-tbl-end-day"),
        align: "center",
        sortable: true,
        value: "end_day",
      },
      {
        text: this.$t("weeksshift-manager-tbl-status"),
        align: "center",
        sortable: true,
        value: "state",
      },
      { text: "", align: "center", sortable: false, value: "actions" },
    ];
  },

  watch: {
    options: {
      handler() {
        this.fetchWeeks();
      },
      deep: true,
    },

    filter: {
      handler() {
        this.currentPage = 1;
        this.fetchWeeks();
      },
    },
  },

  methods: {
    fetchWeeks() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        plannerManager
          .fetchWeeks(ctx)
          .then((result) => {
            this.weeks = result.items;
            this.totalWeeks = result.totalCount;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            reject(err);
          });
      });
    },

    handleEditWeek(event) {
      this.loading = true;
      this.weekEditorDialog = false; // Close dialog
      let week = Object.assign({}, event.week);

      plannerManager
        .editWeek(week)
        .then(() => {
          this.fetchWeeks();
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    handleAddWeek(event) {
      this.loading = true;
      this.addWeekEditorDialog = false; // Close dialog
      plannerManager
        .addWeek(event.week, event.template)
        .then(() => {
          this.fetchWeeks();
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    deleteItem(item) {
      let weekId = item.id;
      this.$refs.msgBox
        .show(
          this.$t("weekmng-msgbox-confirm-delete"),
          this.$t("msgbox-confirm-op")
        )
        .then(() => {
          plannerManager
            .deleteWeek(weekId)
            .then(() => {
              this.fetchWeeks();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    editItem(item) {
      let week = JSON.parse(JSON.stringify(item));
      this.$refs.weekEditor.resetModal();
      this.$refs.weekEditor.setWeek(week, true, item);
      this.weekEditorDialog = true;
    },

    addWeek() {
      this.$refs.addWeekEditor.resetModal();
      this.addWeekEditorDialog = true;
    },
  },
};
</script>
