<template>
  <v-container justify-center fluid grid-list-xl>
    <messageBox ref="msgBox" />
    <v-layout justify-center wrap>
      <v-flex md12>
        <base-material-card
          color="primary"
          icon="mdi-account"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("templates-mng-title") }}
            </div>
          </template>
          <v-text-field
            :value="filter"
            @change="(v) => (filter = v)"
            append-icon="mdi-magnify"
            class="mr-auto pl-1"
            :label="$t('gbl-search')"
            hide-details
            single-line
            style="max-width: 250px"
          />

          <div class="vertical-spacer" />
          <v-data-table
            class="grid-table"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="templates"
            :options.sync="options"
            :server-items-length="totalTemplates"
            :loading="loading"
            :footer-props="dataTableFooterOptions"
          >
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="red"
                    @click.stop="deleteItem(item)"
                    v-on="on"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  ></template
                ><span>{{
                  $t("templates-mng-delete-template")
                }}</span></v-tooltip
              >
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import templatesManager from "@/apis/templates";
import staticData from "@/apis/static";
import messageBox from "@/components/MessageBox.vue";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      templates: [],
      loading: false,
      totalTemplates: 0,
      options: {
        sortBy: ["name"],
        sortDesc: [false],
        itemsPerPage: 50,
        page: 1,
      },
      headers: [],
      perPage: staticData.defaultPerPage,
      filter: "",
    };
  },

  components: { messageBox },

  mounted() {
    this.headers = [
      {
        text: this.$t("templatesmng-tbl-name"),
        align: "left",
        sortable: true,
        value: "name",
      },
      {
        text: this.$t("templatesmng-tbl-shift-count"),
        align: "center",
        sortable: true,
        value: "shift_count",
      },
      {
        text: this.$t("templatesmng-tbl-weeks-count"),
        align: "center",
        sortable: true,
        value: "weeks_count",
      },
      { text: "", align: "center", sortable: false, value: "actions" },
    ];
  },

  watch: {
    options: {
      handler() {
        this.fetchTemplates();
      },
      deep: true,
    },

    filter: {
      handler() {
        this.currentPage = 1;
        this.fetchTemplates();
      },
    },
  },

  methods: {
    fetchTemplates() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.options);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        templatesManager
          .fetchTemplates(ctx)
          .then((result) => {
            this.templates = result.templates;
            this.totalTemplates = result.count;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            reject(err);
          });
      });
    },

    deleteItem(item) {
      let templateId = item.id;
      this.$refs.msgBox
        .show(
          this.$t("templates-mng-msgbox-confirm-delete"),
          this.$t("msgbox-confirm-op")
        )
        .then(() => {
          templatesManager
            .removeTemplate(templateId)
            .then(() => {
              this.fetchTemplates();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
  },
};
</script>
