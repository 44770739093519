<template>
  <div>
    <messageBox ref="msgBox" />
    <addAccountShift
      ref="shiftDialog"
      v-model="addShiftDialog"
      @shiftConfigured="handleShift"
    />
    <v-card class="ma-4">
      <v-toolbar color="primary">
        <v-icon color="white" large class="mr-2"
          >mdi-account-reactivate-outline</v-icon
        >
        <v-toolbar-title class="white--text">{{
          $t("users-shifts-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <div>
        <div style="height: 10px" />
      </div>

      <v-row v-if="$store.state.hasRight('23')" class="text-center">
        <v-col>
          <v-btn
            :disabled="fetchingData"
            color="primary"
            @click.stop="addShiftException()"
            >{{ $t("users-shifts-add-shift-exception") }}</v-btn
          >
        </v-col>
      </v-row>

      <div style="margin: 15px">
        <v-row justify="center">
          <v-col>
            <v-autocomplete
              :disabled="fetchingData"
              clearable
              dense
              no-filter
              v-model="flt_user"
              :items="flt_users"
              :loading="isLoadingUser"
              :search-input.sync="searchUserInput"
              outlined
              item-text="display_name"
              item-value="id"
              :label="$t('filter-user')"
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-select
              outlined
              dense
              :label="$t('logs-mngs-period')"
              ref="period"
              v-model="filters.filterByPeriod"
              :items="getPeriodFilters()"
              item-text="name"
              item-value="id"
            >
            </v-select>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_start_date"
              v-model="date_start_modal"
              :return-value.sync="filters.filterByCustomDateStart"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :disabled="filters.filterByPeriod != 13"
                  dense
                  outlined
                  v-model="start_date_formatted"
                  :label="$t('logs-mngs-datestart')"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_start_date.save(filters.filterByCustomDateStart)
                "
                v-model="filters.filterByCustomDateStart"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_end_date"
              v-model="date_end_modal"
              :return-value.sync="filters.filterByCustomDateEnd"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :disabled="filters.filterByPeriod != 13"
                  dense
                  outlined
                  v-model="end_date_formatted"
                  :label="$t('logs-mngs-dateend')"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                "
                v-model="filters.filterByCustomDateEnd"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </div>

      <v-data-table
        dense
        :items-per-page="perPage"
        :must-sort="true"
        :headers="headers"
        :items="shifts"
        :options.sync="options"
        :server-items-length="totalShifts"
        :loading="fetchingData"
        :footer-props="dataTableFooterOptions"
        item-key="id"
      >
        <template v-slot:item.shift_day="{ item }">
          {{ item.shift_day | toLocaleDate }}
        </template>

        <template v-slot:item.first_name="{ item }">
          {{ item.first_name }} {{ item.last_name }}
        </template>

        <template v-slot:item.actions="{ item }">
          <div style="min-width: 120px">
            <v-tooltip v-if="$store.state.hasRight('24')" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="gray"
                  @click.stop="editShift(item)"
                  v-on="on"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                ></template
              ><span>{{ $t("shift-action-edit") }}</span></v-tooltip
            >

            <v-tooltip v-if="$store.state.hasRight('25')" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="error"
                  @click.stop="deleteShift(item)"
                  v-on="on"
                  ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                ></template
              ><span>{{ $t("shift-action-delete") }}</span></v-tooltip
            >
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import shiftsManager from "../apis/shifts";
import usersManager from "../apis/users";
import addAccountShift from "../components/AddAccountShift.vue";

import staticData from "../apis/static";
import config from "../config";

import messageBox from "../components/MessageBox";

export default {
  data() {
    return {
      staticData: staticData,
      addShiftDialog: false,
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      shifts: [],
      totalShifts: 0,
      perPage: staticData.defaultPerPage,
      filter: null,
      filters: {
        filterByPeriod: 8,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        user: null,
        flt_week: null,
      },

      date_end_modal: false,
      date_start_modal: false,
      options: {
        sortBy: ["c1.last_name", "c1.first_name"],
        sortDesc: [true, true],
        itemsPerPage: 50,
        page: 1,
      },
      headers: [],
      loadingFilters: false,
      flt_user: null,
      flt_users: [],
      searchUserInput: null,
      isLoadingUser: false,
      fetchingData: false,
    };
  },

  components: {
    messageBox,
    addAccountShift,
  },

  mounted() {
    this.headers = [
      {
        text: this.$t("customers-tbl-name"),
        align: "left",
        sortable: true,
        value: "first_name",
      },

      {
        text: this.$t("customers-tbl-shift-day"),
        align: "left",
        sortable: true,
        value: "shift_day",
      },
      {
        text: this.$t("customers-tbl-shift-hour"),
        align: "left",
        sortable: true,
        value: "shift_start_time",
      },
      {
        text: this.$t("customers-tbl-shift-hour"),
        align: "left",
        sortable: true,
        value: "shift_end_time",
      },
      {
        text: this.$t("customers-tbl-shift-reason"),
        align: "left",
        sortable: true,
        value: "reason_name",
      },
      { text: "", align: "right", sortable: false, value: "actions" },
    ];
  },

  watch: {
    searchUserInput(val) {
      clearTimeout(this._searchUserInput);
      if (val) {
        this._searchUserInput = setTimeout(() => {
          this.isLoadingUser = true;
          this.fetchFilterUsers(val)
            .then((res) => {
              this.flt_users = res.items.map((x) => {
                return {
                  display_name: x.first_name + " " + x.last_name,
                  id: x.id,
                };
              });

              this.isLoadingUser = false;
            })
            .catch(() => {
              this.isLoadingUser = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        this.filters.user = null;
      }
    },

    flt_users: {
      handler() {
        this.filters.user = this.flt_user ? this.flt_user.id : null;
      },
    },

    options: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.fetchAccountShifts();
        }, config.searchInputsFastTimeout);
      },
      deep: true,
    },

    filters: {
      handler() {
        this.shifts = [];
        this.totalShifts = 0;

        clearTimeout(this._debounceTimer);
        this.options = {
          ...this.options,
          page: 1,
          sortBy: ["c1.last_name", "c1.first_name"],
          sortDesc: [true, true, true],
        };
      },

      deep: true,
    },

    filter: {
      handler() {
        this.options = {
          ...this.options,
          page: 1,
          sortBy: ["c1.last_name", "c1.first_name"],
          sortDesc: [true, true],
        };
      },
    },
  },

  computed: {
    start_date_formatted: {
      get: function () {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
    },

    end_date_formatted: {
      get: function () {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
    },
  },

  methods: {
    getPeriodFilters: () => {
      return staticData.statisticsPeriods;
    },

    handleShift(shift) {
      if (shift.id) {
        shiftsManager
          .updateAccountShift(shift)
          .then(() => {
            this.fetchAccountShifts();
            this.addShiftDialog = false;
          })
          .catch((err) => {
            console.log(err);
          });
        /* Editing */
      } else {
        shiftsManager
          .addAccountShift(shift)
          .then(() => {
            this.fetchAccountShifts();
            this.addShiftDialog = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    addShiftException() {
      this.$refs.shiftDialog.resetModal();
      this.addShiftDialog = true;
    },

    editShift(item) {
      this.$refs.shiftDialog.setShift(item);
      this.addShiftDialog = true;
    },

    deleteShift(item) {
      this.$refs.msgBox
        .show(
          this.$t("customer-delete-confirm"),
          this.$t("customer-delete-title")
        )
        .then(() => {
          shiftsManager
            .removeAccountShift(item.id)
            .then(() => {
              this.fetchAccountShifts();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    fetchAccountShifts: function () {
      this.fetchingData = true;
      this.shifts = [];
      this.totalShifts = 0;
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.filters = this.filters;
      shiftsManager
        .fetchAccountShifts(ctx)
        .then((results) => {
          this.fetchingData = false;
          this.shifts = results.shifts;
          this.totalShifts = results.count;
        })
        .catch((err) => {
          this.fetchingData = false;
          console.log(err);
        });
    },

    fetchFilterUsers(filter) {
      return new Promise((resolve, reject) => {
        usersManager
          .fetchFilterUsers(filter)
          .then((results) => {
            resolve(results);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
};
</script>
