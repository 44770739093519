<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="800" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-clock</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{
                    shift.id ? $t("shifteditor-title") : $t("shiftadd-title")
                  }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <v-form class="mt-5" ref="weekeditor-editor-form">
              <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
                <v-row dense v-if="shift.id" justify="center">
                  <v-col class="font-weight-bold"
                    >{{
                      shift.business_name
                        ? shift.business_name
                        : shift.first_name + " " + shift.last_name
                    }}
                  </v-col>
                </v-row>
                <v-row dense v-if="!shift.id" justify="center">
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-autocomplete
                        :disabled="fetchingData"
                        clearable
                        dense
                        no-filter
                        v-model="flt_customer"
                        :items="flt_customers"
                        :loading="isLoadingCustomer"
                        :search-input.sync="searchCustomerInput"
                        outlined
                        item-text="display_name"
                        item-value="id"
                        :label="$t('customers-filter-customer')"
                        return-object
                      ></v-autocomplete
                    ></ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense justify="center">
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-select
                        :disabled="
                          fetchingData || !shift.customer_id || isLoadingAddress
                        "
                        dense
                        no-filter
                        v-model="shift.address_id"
                        :items="flt_addresses"
                        outlined
                        item-text="name"
                        item-value="id"
                        :label="$t('customers-filter-address')"
                      ></v-select
                    ></ValidationProvider>
                  </v-col>
                </v-row>

                <v-row dense align="center">
                  <v-col cols="8">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-select
                        :label="$t('shifteditor-days')"
                        item-text="name"
                        item-value="id"
                        outlined
                        dense
                        v-model="shift.shift_days"
                        :items="computeDays"
                        :error-messages="errors"
                        :success="valid"
                        multiple
                      >
                      </v-select>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      class="mb-4"
                      v-model="shift.flexible_day"
                      :label="$t('shifteditor-flexible-day')"
                      :disabled="fetchingData"
                      required
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row dense align="center">
                  <v-col cols="4">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        type="time"
                        outlined
                        dense
                        v-model="shift.shift_start_time"
                        :label="$t('shifteditor-time-start')"
                        prepend-icon="mdi-clock"
                        :error-messages="errors"
                        :success="valid"
                        :disabled="fetchingData"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="4">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        type="time"
                        outlined
                        dense
                        v-model="shift.shift_end_time"
                        :label="$t('shifteditor-time-end')"
                        prepend-icon="mdi-clock"
                        :error-messages="errors"
                        :success="valid"
                        :disabled="fetchingData"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="4">
                    <v-checkbox
                      class="mb-4"
                      v-model="shift.flexible_time"
                      :label="$t('shifteditor-flexible-hour')"
                      :disabled="fetchingData"
                      required
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row dense align="center">
                  <v-col cols="4">
                    <ValidationProvider
                      rules="required|min_value:1"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="shift.shift_operators"
                        :label="$t('shifteditor-shift-operators')"
                        prepend-icon="mdi-account-multiple"
                        :error-messages="errors"
                        :success="valid"
                        :disabled="fetchingData"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="4">
                    <ValidationProvider
                      rules="required|min_value:0"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="computeShiftHours"
                        :label="$t('shifteditor-shift-hours')"
                        prepend-icon="mdi-account-clock"
                        :disabled="fetchingData"
                        readonly
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="4">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      immediate
                    >
                      <v-text-field
                        outlined
                        readonly
                        dense
                        :value="computeTotalHours"
                        :label="$t('shifteditor-shift-total-hours')"
                        prepend-icon="mdi-clock-outline"
                        :disabled="fetchingData"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-alert
                      dense
                      v-if="shift.shift_start_time > shift.shift_end_time"
                      type="error"
                      >{{ $t("shifteditor-time-error") }}</v-alert
                    >
                  </v-col>
                </v-row>

                <v-divider />

                <div style="height: 10px" />

                <v-row dense>
                  <v-col>
                    <v-btn
                      width="120"
                      :disabled="
                        invalid ||
                        !validated ||
                        fetchingData ||
                        shift.shift_start_time > shift.shift_end_time
                      "
                      v-on:click="confirm()"
                      color="orange"
                      >{{ $t("gbl-ok") }}</v-btn
                    >
                    <v-btn width="120" v-on:click="cancel()" color="primary">{{
                      $t("gbl-cancel")
                    }}</v-btn>
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-form>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import customersManager from "../apis/customers";
import staticData from "../apis/static";
import config from "../config";

export default {
  data() {
    return {
      fetchingData: false,
      days: [...staticData.days],
      shift: {
        id: null,
        shift_days: [],
        shift_end_time: null,
        shift_start_time: null,
        flexible_time: true,
        flexible_day: true,
        customer_id: null,
        address_id: null,
        shift_operators: 1,
        shift_hours: 1,
      },

      flt_customer: null,
      flt_customers: [],
      searchCustomerInput: null,
      isLoadingCustomer: false,
      isLoadingAddress: false,

      flt_addresses: [
        {
          id: null,
          disabled: true,
          name: this.$t("customers-filter-select-address"),
        },
      ],
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: ["value"],

  mounted() {},

  watch: {
    "shift.shift_operators": {
      handler() {
        this.rebuildShiftHours();
      },
    },

    "shift.shift_start_time": {
      handler() {
        this.rebuildShiftHours();
      },
    },
    "shift.shift_end_time": {
      handler() {
        this.rebuildShiftHours();
      },
    },
    searchCustomerInput(val) {
      clearTimeout(this._searchCustomerInput);
      if (val) {
        this._searchCustomerInput = setTimeout(() => {
          this.isLoadingCustomer = true;
          this.fetchFilterCustomers(val)
            .then((res) => {
              this.flt_customers = res.customers.map((x) => {
                if (x.business_name) x.display_name = x.business_name;
                else x.display_name = x.first_name + " " + x.last_name;
                x.closed_days = x.closed_days.split(",").map((x) => {
                  return parseInt(x);
                });
                return x;
              });
              this.isLoadingCustomer = false;
            })
            .catch(() => {
              this.isLoadingCustomer = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        this.shift.customer_id = null;
        this.shift.address_id = null;
      }
    },

    flt_customers: {
      handler() {
        this.shift.customer_id = this.flt_customer
          ? this.flt_customer.id
          : null;
      },
    },

    flt_customer: {
      handler(val) {
        if (!val) {
          this.flt_addresses = [];
          this.shift.address_id = null;
        } else {
          this.flt_addresses = [
            {
              id: null,
              disabled: true,
              name: this.$t("customers-filter-select-address"),
            },
          ];
          this.shift.address_id = null;
          this.fetchFilterAddressess(val.id)
            .then((res) => {
              this.flt_addresses = res.map((x) => {
                x.name = x.business_name
                  ? x.business_name
                  : x.first_name + " " + x.last_name;
                x.name += " - " + x.address;

                return x;
              });
              this.flt_addresses = [
                {
                  id: null,
                  disabled: true,
                  name: this.$t("customers-filter-select-address"),
                },
                ...this.flt_addresses,
              ];
            })
            .catch(() => {});
        }
      },
    },
  },

  computed: {
    computeDays() {
      let days = [...staticData.days];
      if (this.flt_customer) {
        /*days = days.map((x) => {
           if (this.flt_customer.closed_days.includes(x.id)) {
            x.name = x.name + " (" + this.$t("customers-closed-day") + ")";
          }
          return x;
        });*/
      }
      return days;
    },

    computeTotalHours() {
      if (!this.shift.shift_hours || !this.shift.shift_operators) return 0;
      let total = this.shift.shift_hours * this.shift.shift_operators;
      return total.toFixed(2);
    },

    computeShiftHours() {
      return this.shift.shift_hours.toFixed(2);
    },
  },
  methods: {
    rebuildShiftHours() {
      this.shift.shift_hours = 1;
      // Calculate hours using start time and end time
      if (
        this.shift.shift_start_time &&
        this.shift.shift_end_time &&
        this.shift.shift_start_time < this.shift.shift_end_time
      ) {
        let start = this.shift.shift_start_time.split(":");
        let end = this.shift.shift_end_time.split(":");
        let start_hour = parseInt(start[0]);
        let start_minute = parseInt(start[1]);
        let end_hour = parseInt(end[0]);
        let end_minute = parseInt(end[1]);
        let hours = end_hour - start_hour;
        let minutes = end_minute - start_minute;
        if (minutes < 0) {
          hours--;
          minutes = 60 + minutes;
        }
        this.shift.shift_hours = hours + minutes / 60;
      }
    },

    fetchFilterCustomers(filter) {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchFilterCustomers(filter)
          .then((results) => {
            resolve(results);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchFilterAddressess(customer_id) {
      this.isLoadingAddress = true;
      return new Promise((resolve, reject) => {
        customersManager
          .fetchAddresses(customer_id)
          .then((results) => {
            this.isLoadingAddress = false;
            resolve(results.location_addresses);
          })
          .catch((err) => {
            this.isLoadingAddress = false;
            console.log(err);
            reject(err);
          });
      });
    },

    setShift(shift) {
      this.$set(this, "shift", { ...shift });
      this.shift.shift_days = this.shift.shift_days.split(",").map((x) => {
        return parseInt(x);
      });
      if (this.shift.id) {
        this.fetchFilterAddressess(this.shift.customer_id)
          .then((res) => {
            this.flt_addresses = res.map((x) => {
              x.name = x.business_name
                ? x.business_name
                : x.first_name + " " + x.last_name;
              x.name += " - " + x.address;

              return x;
            });
            this.flt_addresses = [
              {
                id: null,
                disabled: true,
                name: this.$t("customers-filter-select-address"),
              },
              ...this.flt_addresses,
            ];
          })
          .catch(() => {});
      }
    },
    confirm: function () {
      this.$emit("shiftConfigured", this.shift);
    },

    closeDialog: function () {
      this.cancel();
    },

    cancel: function () {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
      this.resetModal();
    },

    resetModal() {
      this.shift = {
        id: null,
        shift_days: [],
        shift_end_time: null,
        shift_start_time: null,
        flexible_time: true,
        flexible_day: true,
        shift_hours: 1,
        shift_operators: 1,
        customer_id: null,
        address_id: null,
      };
      this.days = [...staticData.days];
      this.flt_customer = null;
      this.flt_customers = [];
      this.flt_addresses = [];
    },
  },
};
</script>
