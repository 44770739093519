<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="800" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-account</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("usreditor-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <div style="height: 10px" v-if="fetchingData" />

            <v-row v-if="fetchingData">
              <v-col>
                <v-progress-circular
                  :indeterminate="true"
                  :rotate="0"
                  :size="32"
                  :width="4"
                  color="primary"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-form class="mt-5" v-if="!fetchingData" ref="user-editor-form">
              <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      :immediate="editingUser"
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="user.first_name"
                        :label="$t('usreditor-hint-name')"
                        prepend-icon="mdi-account"
                        :error-messages="errors"
                        :success="valid"
                        required
                        counter="64"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col>
                    <ValidationProvider
                      :immediate="editingUser"
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="user.last_name"
                        :label="$t('usreditor-hint-surname')"
                        prepend-icon="mdi-account"
                        :error-messages="errors"
                        :success="valid"
                        required
                        counter="64"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      :immediate="true"
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="user.email"
                        :label="$t('usreditor-hint-email')"
                        prepend-icon="mdi-email"
                        :error-messages="errors"
                        :success="valid"
                        required
                        counter="64"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <v-text-field
                      outlined
                      dense
                      v-model="user.mobile"
                      :label="$t('usreditor-hint-mobile')"
                      prepend-icon="mdi-phone"
                      counter="32"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-select
                      hide-details
                      :label="$t('usreditor-hint-role')"
                      outlined
                      dense
                      v-model="user.role"
                      prepend-icon="mdi-account-supervisor"
                      item-text="name"
                      item-value="id"
                      :items="roles"
                    />
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      :immediate="editingUser"
                      rules="required|min_value:0|max_value:100|numeric"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="user.contract_hours"
                        :label="$t('usreditor-hint-week-contract-hours')"
                        prepend-icon="mdi-signature-freehand"
                        :error-messages="errors"
                        :success="valid"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <v-text-field
                      outlined
                      dense
                      v-model="user.ope_code"
                      :label="$t('usreditor-hint-ope-code')"
                      prepend-icon="mdi-barcode"
                      :error-messages="errors"
                      :success="valid"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row> </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      v-if="!editingUser"
                      rules="required"
                      v-slot="{ errors, valid }"
                      vid="password_confirm"
                    >
                      <v-text-field
                        autocomplete="new-password"
                        v-model="user.password"
                        prepend-icon="mdi-lock"
                        :label="$t('usreditor-hint-password')"
                        :append-icon="password_view ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="() => (password_view = !password_view)"
                        :type="password_view ? 'password' : 'text'"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col>
                    <ValidationProvider
                      v-if="!editingUser"
                      rules="required|confirmed:password_confirm"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="repeat_password"
                        prepend-icon="mdi-lock"
                        :label="$t('usreditor-hint-repeatpassword')"
                        :error-messages="errors"
                        :append-icon="
                          password_confirm ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        @click:append="
                          () => (password_confirm = !password_confirm)
                        "
                        :type="password_confirm ? 'password' : 'text'"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <div style="height: 10px" />

                <v-row v-if="user && user.role && user.role == 'u'">
                  <v-col>
                    <v-card>
                      <v-toolbar dense color="primary accent-4" dark>
                        <v-toolbar-title class="white--text ma-0 text-center">{{
                          $t("usreditor-groups")
                        }}</v-toolbar-title>
                      </v-toolbar>
                      <v-card-text class="pa-0">
                        <v-row class="pa-0">
                          <v-col class="pa-0">
                            <v-list>
                              <v-list-item-group>
                                <v-list-item
                                  v-for="item in usersGroups"
                                  :key="item.id"
                                >
                                  <v-list-item-icon>
                                    <v-icon>mdi-accounts-multiple</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-text="item.name"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                  <v-btn
                                    @click="removeGroup(item)"
                                    icon
                                    dark
                                    color="error"
                                  >
                                    <v-icon>mdi-delete-circle-outline</v-icon>
                                  </v-btn>
                                </v-list-item></v-list-item-group
                              ></v-list
                            >
                          </v-col>
                          <v-col class="pa-0">
                            <v-list>
                              <v-list-item-group>
                                <v-list-item
                                  v-for="item in availableGroups"
                                  :key="item.id"
                                >
                                  <v-list-item-icon>
                                    <v-icon>mdi-accounts-multiple</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-text="item.name"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                  <v-btn
                                    @click="addGroup(item)"
                                    icon
                                    dark
                                    color="green"
                                  >
                                    <v-icon>mdi-plus</v-icon>
                                  </v-btn>
                                </v-list-item>
                              </v-list-item-group></v-list
                            >
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row v-if="user && user.role && user.role == 'u'">
                  <v-col>
                    <v-card>
                      <v-toolbar dense color="primary accent-4" dark>
                        <v-toolbar-title class="white--text ma-0 text-center">{{
                          $t("usreditor-rights")
                        }}</v-toolbar-title>
                      </v-toolbar>
                      <v-card-text class="pa-0">
                        <v-row class="pa-0">
                          <v-col class="pa-0">
                            <v-list>
                              <v-list-group
                                v-for="right in rights"
                                :key="right.item.id"
                                v-model="right.item.active"
                                no-action
                              >
                                <v-icon slot="prependIcon" color="success"
                                  >mdi-application</v-icon
                                >
                                <template v-slot:activator>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      class="text-left"
                                      v-text="right.item.app_description"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </template>
                                <v-list-item
                                  v-for="child in right.childs"
                                  :key="child.id"
                                >
                                  <v-list-item-title
                                    class="text-left"
                                    v-text="child.name"
                                  ></v-list-item-title>
                                  <v-list-item-icon>
                                    <v-btn
                                      v-if="!child.inherited"
                                      @click="removeRight(child)"
                                      icon
                                      dark
                                      color="red"
                                    >
                                      <v-icon>mdi-delete-circle-outline</v-icon>
                                    </v-btn>
                                    <v-icon v-if="child.inherited"
                                      >mdi-account-group</v-icon
                                    >
                                  </v-list-item-icon>
                                </v-list-item>
                              </v-list-group>
                            </v-list>
                          </v-col>
                          <v-col class="pa-0">
                            <v-list>
                              <v-list-group
                                v-for="right in availableRights"
                                :key="right.item.id"
                                v-model="right.item.active"
                                no-action
                              >
                                <v-icon slot="prependIcon" color="primary"
                                  >mdi-application</v-icon
                                >
                                <template v-slot:activator>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      class="text-left"
                                      v-text="right.item.app_description"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </template>

                                <v-list-item
                                  v-for="child in right.childs"
                                  :key="child.id"
                                >
                                  <v-list-item-title
                                    class="text-left"
                                    v-text="child.name"
                                  ></v-list-item-title>
                                  <v-btn
                                    @click="addRight(child)"
                                    icon
                                    dark
                                    color="green"
                                  >
                                    <v-icon>mdi-plus</v-icon>
                                  </v-btn>
                                </v-list-item>
                              </v-list-group>
                            </v-list>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn
                      width="120"
                      :disabled="invalid || !validated"
                      v-on:click="confirm()"
                      color="orange"
                      >{{ $t("gbl-ok") }}</v-btn
                    >
                    <v-btn width="120" v-on:click="cancel()" color="primary">{{
                      $t("gbl-cancel")
                    }}</v-btn>
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-form>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import userManager from "../apis/users";
import applicationManager from "../apis/applications";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      password_confirm: String,
      password_view: String,
      user: {
        role: "u",
        contract_hours: 0,
        ope_code: "",
      },
      groups: [],
      groupsById: {},
      usersGroups: [],
      availableGroups: [],
      repeat_password: "",

      applications: {},
      applications_rights: {},
      availableRights: {},
      rights: {},

      roles: [],
      editingUser: false,
      fetchingData: false,
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: ["value"],

  mounted() {},

  methods: {
    addGroup: function (item) {
      let items = this.user.groups
        .split(",")
        .filter((i) => i)
        .map(Number);
      items.sort((a, b) => a - b);
      if (!items.includes(item.id)) items.push(item.id);
      this.user.groups = items.join(",");
      this.calculateRights();
    },

    removeGroup: function (item) {
      let items = this.user.groups
        .split(",")
        .filter((i) => i != item.id)
        .map(Number);
      items.sort((a, b) => a - b);
      this.user.groups = items.join(",");
      this.calculateRights();
    },

    removeRight: function (item) {
      let items = this.user.rights.split(",").filter((i) => i);
      items = items.filter((x) => x != item.id);
      items.sort((a, b) => a - b);
      this.user.rights = items.join(",");
      this.calculateRights();
    },

    addRight: function (item) {
      let items = this.user.rights.split(",").filter((i) => i);
      items.push(item.id);
      items.sort((a, b) => a - b);
      this.user.rights = items.join(",");
      this.calculateRights();
    },

    reloadAppicationsAndPermissions: function () {
      return new Promise((resolve, reject) => {
        this.applications = {};
        this.rights = {};
        this.fetchApplicationsAndPermissions()
          .then((data) => {
            for (let index in data.items) {
              let app = data.items[index];
              if (!this.applications[app.name]) {
                this.applications[app.name] = {
                  description: app.description,
                  rights: [],
                };
              }

              this.applications[app.name].rights.push({
                id: app.right_id,
                name: app.right_name,
              });
              this.applications_rights[app.right_id] = {
                id: app.right_id,
                name: app.right_name,
                app_name: app.name,
                app_description: app.description,
              };
            }
            this.fetchGroups()
              .then((data) => {
                this.groups = data.items;
                for (let item in this.groups) {
                  let group = this.groups[item];
                  if (group) {
                    this.groupsById[group.id] = group;
                    if (!group.rights) group.rights = "";
                    group.rightsArray = group.rights
                      .split(",")
                      .filter((i) => i);
                  }
                }
                resolve();
              })
              .catch((err) => {
                console.log(err);
                reject(err);
              });
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    fetchGroups() {
      return new Promise((resolve, reject) => {
        userManager
          .fetchGroups({})
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },

    fetchApplicationsAndPermissions() {
      return new Promise((resolve, reject) => {
        applicationManager
          .fetchApplicationsAndPermissions()
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },

    buildAvailableRights() {
      this.availableRights = {};
      for (let right in this.applications_rights) {
        let app_right_obj = this.applications_rights[right];
        let right_obj = this.rights[app_right_obj.app_name];
        if (
          !right_obj ||
          !right_obj.childs.some((item) => {
            return item.id == app_right_obj.id;
          })
        ) {
          if (!this.availableRights[app_right_obj.app_name])
            this.availableRights[app_right_obj.app_name] = {
              item: app_right_obj,
              childs: [],
            };
          this.availableRights[app_right_obj.app_name].childs.push(
            app_right_obj
          );
        }
      }
    },

    calculateRights() {
      let user_groups = [];
      let group_rights = [];
      this.availableGroups = [];
      this.usersGroups = [];
      this.availableGroups = [];
      if (this.user.groups)
        user_groups = this.user.groups.split(",").filter((i) => i);
      for (let index in user_groups) {
        let group = user_groups[index];
        let groupItem = this.groupsById[group];
        if (groupItem) {
          this.usersGroups.push(groupItem);
          group_rights = [...group_rights, ...groupItem.rightsArray];
        }
      }

      for (let n = 0; n < this.groups.length; n++) {
        let group = this.groups[n];
        if (!this.usersGroups.includes(group)) this.availableGroups.push(group);
      }

      let user_rights = [];
      this.rights = {};
      if (this.user.rights)
        user_rights = this.user.rights.split(",").filter((i) => i);
      user_rights = [...user_rights, ...group_rights].filter(
        (v, i, a) => a.indexOf(v) === i
      );

      for (let index in user_rights) {
        let right = user_rights[index];
        if (this.applications_rights[right]) {
          let right_obj = this.applications_rights[right];
          if (group_rights.includes(right_obj.id.toString()))
            right_obj.inherited = true;
          else right_obj.inherited = false;

          if (!this.rights[right_obj.app_name])
            this.rights[right_obj.app_name] = { item: right_obj, childs: [] };
          this.rights[right_obj.app_name].childs.push(right_obj);
        }
      }

      this.buildAvailableRights();
    },

    confirm: function () {
      this.handleSubmit();
    },

    closeDialog: function () {
      this.cancel();
    },

    cancel: function () {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    handleSubmit() {
      this.$refs.obs.reset();
      this.$emit("userConfigured", {
        editingUser: this.editingUser,
        user: this.user,
      });
    },

    resetModal() {
      this.repeat_password = "";
      this.user.password = "";
    },

    setModalUser(user, editing) {
      this.fetchingData = true;

      this.user = Object.assign({}, user);
      this.editingUser = editing;
      if (!this.editingUser) this.resetModal();
      if (
        this.user.rights
          .split(",")
          .filter((i) => i)
          .includes("a")
      )
        this.user.role = "a";
      else if (
        this.user.rights
          .split(",")
          .filter((i) => i)
          .includes("u")
      )
        this.user.role = "u";

      this.roles = [{ id: "u", name: "Utente" }];
      if (this.$store.state.isSuperAdmin())
        this.roles.push({ id: "a", name: "Amministratore" });

      this.reloadAppicationsAndPermissions()
        .then(() => {
          this.calculateRights();
          this.fetchingData = false;
          if (editing) {
            requestAnimationFrame(() => {
              if (this.$refs.obs) {
                this.$refs.obs.validate();
              }
            });
          } else {
            requestAnimationFrame(() => {
              if (this.$refs.obs) this.$refs.obs.reset();
            });
          }
        })
        .catch((err) => {
          this.fetchingData = false;
          console.log(err);
        });

      /* eslint-enable no-unused-vars */
    },
  },
};
</script>
