<template>
  <div>
    <messageBox ref="msgBox" />
    <addShift
      ref="shiftDialog"
      v-model="addShiftDialog"
      @shiftConfigured="handleShift"
    />
    <v-card class="ma-4">
      <v-toolbar color="primary">
        <v-icon color="white" large class="mr-2"
          >mdi-account-reactivate-outline</v-icon
        >
        <v-toolbar-title class="white--text">{{
          $t("registry-shifts-title")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <div>
        <div style="height: 10px" />
      </div>

      <v-row v-if="$store.state.hasRight('23')" class="text-center">
        <v-col>
          <v-btn
            :disabled="fetchingData"
            color="primary"
            @click.stop="addShift()"
            >{{ $t("registry-shifts-add-shift") }}</v-btn
          >
        </v-col>
      </v-row>

      <div style="margin: 15px">
        <v-row justify="center">
          <v-col>
            <v-autocomplete
              :disabled="fetchingData"
              clearable
              dense
              no-filter
              v-model="flt_customer"
              :items="flt_customers"
              :loading="isLoadingCustomer"
              :search-input.sync="searchCustomerInput"
              outlined
              item-text="display_name"
              item-value="id"
              :label="$t('customers-filter-customer')"
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-select
              :label="$t('planner-mng-selected-week')"
              outlined
              item-text="week_name"
              item-value="id"
              dense
              hide-details
              :items="flt_weeks"
              v-model="filters.flt_week"
              :disabled="
                loadingFilters ||
                filters.filterByPeriod == 14 ||
                (flt_weeks && flt_weeks.length < 1)
              "
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              outlined
              dense
              :label="$t('logs-mngs-period')"
              ref="period"
              v-model="filters.filterByPeriod"
              :items="getPeriodFilters()"
              item-text="name"
              item-value="id"
            >
            </v-select>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_start_date"
              v-model="date_start_modal"
              :return-value.sync="filters.filterByCustomDateStart"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :disabled="filters.filterByPeriod != 13"
                  dense
                  outlined
                  v-model="start_date_formatted"
                  :label="$t('logs-mngs-datestart')"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_start_date.save(filters.filterByCustomDateStart)
                "
                v-model="filters.filterByCustomDateStart"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_end_date"
              v-model="date_end_modal"
              :return-value.sync="filters.filterByCustomDateEnd"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :disabled="filters.filterByPeriod != 13"
                  dense
                  outlined
                  v-model="end_date_formatted"
                  :label="$t('logs-mngs-dateend')"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                "
                v-model="filters.filterByCustomDateEnd"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </div>

      <v-data-table
        dense
        :items-per-page="perPage"
        :must-sort="true"
        :headers="headers"
        :items="shifts"
        :options.sync="options"
        :server-items-length="totalShifts"
        :loading="fetchingData"
        :footer-props="dataTableFooterOptions"
        item-key="id"
      >
        <template v-slot:item.customer_color="{ item }">
          <v-icon :color="item.reference_color">mdi-circle</v-icon>
        </template>

        <template v-slot:item.business_name="{ item }">
          {{
            item.business_name
              ? item.business_name
              : item.first_name + " " + item.last_name
          }}
        </template>
        <template v-slot:item.address="{ item }">
          {{
            item.addr_business_name
              ? item.addr_business_name
              : item.addr_first_name + " " + addr_item.last_name
          }}
          - {{ item.addr_address }}
        </template>

        <template v-slot:item.shift_days="{ item }">
          {{
            item.shift_days
              .split(",")
              .map((x) => staticData.days[x].name.substr(0, 2))
              .join(" ")
          }}
        </template>

        <template v-slot:item.actions="{ item }">
          <div style="min-width: 120px">
            <v-tooltip v-if="filters.flt_week" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="red"
                  @click.stop="sendToPlanner(item)"
                  v-on="on"
                  ><v-icon>mdi-arrow-right</v-icon></v-btn
                ></template
              ><span>{{ $t("shift-action-send-to-planner") }}</span></v-tooltip
            >

            <v-tooltip v-if="!filters.flt_week" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn x-small text icon color="gray" v-on="on"
                  ><v-icon>mdi-arrow-right</v-icon></v-btn
                ></template
              ><span>{{
                $t("shift-action-send-to-planner-warning")
              }}</span></v-tooltip
            >

            <v-tooltip v-if="!item.enabled" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="red"
                  @click.stop="enableShift(item)"
                  v-on="on"
                  ><v-icon>mdi-card-bulleted-outline</v-icon></v-btn
                ></template
              ><span>{{ $t("shift-action-enable-shift") }}</span></v-tooltip
            >

            <v-tooltip v-if="item.enabled" bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="primary"
                  @click.stop="disableShift(item)"
                  v-on="on"
                  ><v-icon>mdi-card-bulleted-off-outline</v-icon></v-btn
                ></template
              ><span>{{ $t("shift-action-disable-shift") }}</span></v-tooltip
            >

            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="gray"
                  @click.stop="editShift(item)"
                  v-on="on"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                ></template
              ><span>{{ $t("shift-action-edit") }}</span></v-tooltip
            >

            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  x-small
                  text
                  icon
                  color="error"
                  @click.stop="deleteShift(item)"
                  v-on="on"
                  ><v-icon>mdi-delete-circle-outline</v-icon></v-btn
                ></template
              ><span>{{ $t("shift-action-delete") }}</span></v-tooltip
            >
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import shiftsManager from "../apis/shifts";
import customersManager from "../apis/customers";
import addShift from "../components/AddShift.vue";

import staticData from "../apis/static";
import config from "../config";

import messageBox from "../components/MessageBox";

export default {
  data() {
    return {
      staticData: staticData,
      addShiftDialog: false,
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      shifts: [],
      totalShifts: 0,
      perPage: staticData.defaultPerPage,
      filter: null,
      date_end_modal: false,
      date_start_modal: false,
      filters: {
        filterByPeriod: 8,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        customer: null,
        flt_week: null,
      },

      flt_weeks: [],
      options: {
        sortBy: ["c1.business_name", "c1.last_name", "c1.first_name"],
        sortDesc: [true, true, true],
        itemsPerPage: 50,
        page: 1,
      },

      headers: [],
      loadingFilters: false,
      flt_customer: null,
      flt_customers: [],
      searchCustomerInput: null,
      isLoadingCustomer: false,
      fetchingData: false,
    };
  },

  components: {
    messageBox,
    addShift,
  },

  computed: {
    start_date_formatted: {
      get: function () {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
    },

    end_date_formatted: {
      get: function () {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
    },
  },

  mounted() {
    this.headers = [
      {
        text: null,
        align: "center",
        sortable: false,
        value: "customer_color",
      },
      {
        text: this.$t("customers-tbl-business-name"),
        align: "center",
        sortable: true,
        value: "business_name",
      },
      {
        text: this.$t("customers-tbl-address"),
        align: "center",
        sortable: true,
        value: "address",
      },
      {
        text: this.$t("customers-tbl-email"),
        align: "center",
        sortable: true,
        value: "email",
      },

      {
        text: this.$t("customers-tbl-shift-day"),
        align: "center",
        sortable: true,
        value: "shift_days",
      },
      {
        text: this.$t("customers-tbl-shift-hour"),
        align: "center",
        sortable: true,
        value: "shift_start_time",
      },
      {
        text: this.$t("customers-tbl-shift-hour"),
        align: "center",
        sortable: true,
        value: "shift_end_time",
      },
      {
        text: this.$t("customers-tbl-shift-operators"),
        align: "center",
        sortable: true,
        value: "shift_operators",
      },

      {
        text: this.$t("customers-tbl-shift-flexible-day"),
        align: "center",
        sortable: true,
        value: "flexible_day",
      },
      {
        text: this.$t("customers-tbl-shift-flexible-hour"),
        align: "center",
        sortable: true,
        value: "flexible_time",
      },
      { text: "", align: "right", sortable: false, value: "actions" },
    ];
  },

  watch: {
    searchCustomerInput(val) {
      clearTimeout(this._searchCustomerInput);
      if (val) {
        this._searchCustomerInput = setTimeout(() => {
          this.isLoadingCustomer = true;
          this.fetchFilterCustomers(val)
            .then((res) => {
              this.flt_customers = res.customers.map((x) => {
                if (x.business_name) x.display_name = x.business_name;
                else x.display_name = x.first_name + " " + x.last_name;
                return x;
              });
              this.isLoadingCustomer = false;
            })
            .catch(() => {
              this.isLoadingCustomer = false;
            });
        }, config.searchInputsFastTimeout);
      } else {
        this.filters.customer = null;
      }
    },

    flt_customer: {
      handler() {
        this.filters.customer = this.flt_customer ? this.flt_customer.id : null;
      },
    },

    options: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.fetchShifts();
        }, config.searchInputsFastTimeout);
      },
      deep: true,
    },

    filters: {
      handler() {
        this.shifts = [];
        this.totalShifts = 0;

        clearTimeout(this._debounceTimer);
        this.options = {
          ...this.options,
          page: 1,
          sortBy: ["c1.business_name", "c1.last_name", "c1.first_name"],
          sortDesc: [true, true, true],
        };
      },

      deep: true,
    },

    filter: {
      handler() {
        this.options = {
          ...this.options,
          page: 1,
          sortBy: ["c1.business_name", "c1.last_name", "c1.first_name"],
          sortDesc: [true, true, true],
        };
      },
    },
  },

  methods: {
    getPeriodFilters: () => {
      return staticData.statisticsPeriods;
    },
    handleShift(shift) {
      if (shift.id) {
        shiftsManager
          .updateShift(shift)
          .then(() => {
            this.fetchShifts();
            this.addShiftDialog = false;
          })
          .catch((err) => {
            console.log(err);
          });
        /* Editing */
      } else {
        shiftsManager
          .addShift(shift)
          .then(() => {
            this.fetchShifts();
            this.addShiftDialog = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.$refs.msgBox
        .show(
          this.$t("shift-mng-shift-updated"),
          this.$t("shift-mng-shift-updated-title"),
          true
        )
        .then(() => {})
        .catch(() => {});
    },

    addShift() {
      this.$refs.shiftDialog.resetModal();
      this.addShiftDialog = true;
    },

    editShift(item) {
      this.$refs.shiftDialog.setShift(item);
      this.addShiftDialog = true;
    },

    disableShift(item) {
      this.$refs.msgBox
        .show(
          this.$t("shift-mng-disable-shift-confirm"),
          this.$t("shift-mng-disable-shift-title")
        )
        .then(() => {
          shiftsManager
            .disableShift(item.id)
            .then(() => {
              this.fetchShifts();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    sendToPlanner(item) {
      this.$refs.msgBox
        .show(
          this.$t("shift-mng-send-to-planner-confirm"),
          this.$t("shift-mng-send-to-planner-title")
        )
        .then(() => {
          shiftsManager
            .sendShiftToPlanner(item.id, this.filters.flt_week)
            .then((/*res*/) => {
              //console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    enableShift(item) {
      this.$refs.msgBox
        .show(
          this.$t("shift-mng-enable-confirm"),
          this.$t("shift-mng-enable-title")
        )
        .then(() => {
          shiftsManager
            .enableShift(item.id)
            .then(() => {
              this.fetchShifts();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    deleteShift(item) {
      this.$refs.msgBox
        .show(
          this.$t("customer-delete-confirm"),
          this.$t("customer-delete-title")
        )
        .then(() => {
          shiftsManager
            .removeShift(item.id)
            .then(() => {
              this.fetchShifts();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    fetchShifts: function () {
      this.fetchingData = true;
      this.shifts = [];
      this.totalShifts = 0;
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.filters = this.filters;
      shiftsManager
        .fetchShifts(ctx)
        .then((results) => {
          this.fetchingData = false;
          this.shifts = results.shifts;
          this.flt_weeks = [
            {
              id: null,
              week_name: "Seleziona una settimana",
              disabled: true,
            },
            ...results.weeks,
          ];
          this.totalShifts = results.count;
        })
        .catch((err) => {
          this.fetchingData = false;
          console.log(err);
        });
    },

    fetchFilterCustomers(filter) {
      return new Promise((resolve, reject) => {
        customersManager
          .fetchFilterCustomers(filter)
          .then((results) => {
            resolve(results);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
};
</script>
